
import axios from 'axios'
export default {
  namespaced: true,
  state: {
    checkLoading: true,
    checkResponse: null,
    checkError: null,
    //////////////////////////////////////////
  },
  getters: {
    checkLoading: state => state.checkLoading,
    checkResponse: state => state.checkResponse,
    checkError: state => state.checkError,
    //////////////////////////////////////////
  },
  mutations: {
    setCheckLoading(state, loading) {
      state.checkLoading = loading
    },
    setCheckResponse(state, data) {
      state.checkResponse = data
    },
    setCheckError(state, error) {
      state.checkError = error
    },
    //////////////////////////////////////////
  },
  actions: {
    async check({ commit }, key) {
      commit('setCheckLoading', true)
      try {
        const response = await axios.get(`/meeting/${key}`)
        // console.log(response)
        commit('setCheckResponse', response)
        commit('setCheckError', null)
        return Promise.resolve(response)
      } catch (error) {
        // console.log(error)
        commit('setCheckError', error)
        return Promise.reject(error.response)
      }
      finally {
        commit('setCheckLoading', false)
      }
    },
  },
};
